import React from "react";

const ProfileIcon = () => (
  <svg
    height="32"
    width="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeLinecap="round" strokeWidth="1.2" transform="translate(0.5 0.5)">
      <path
        d="M12.284,19.053l-4.88,3.065 C6.53,22.667,6,23.626,6,24.658v2.522"
        fill="none"
      />
      <path
        d="M19.716,19.053l4.88,3.065 C25.47,22.667,26,23.626,26,24.658v2.522"
        fill="none"
      />
      <path
        d="M10,13c0-3.314,2.686-6,6-6 s6,2.686,6,6s-2.417,8-6,8C12.458,21,10,16.314,10,13z"
        fill="none"
      />
      <circle cx="16" cy="16" fill="none" r="15" />
    </g>
  </svg>
);

export default ProfileIcon;
