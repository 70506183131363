/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import styled from "styled-components";
import { fontSizeSmaller } from "../../../styles/theme";
import { ContentTitle } from "../tenants/TenantsPage";
import { BAR_PROPS } from "./SyncSection";

const ActivitiesSection = ({ keys, data, view, handleChangeView }) => (
  <>
    <ContentTitle>Activity statistics</ContentTitle>
    {data ? (
      <Container>
        <ResponsiveBar
          {...BAR_PROPS}
          data={data}
          keys={keys}
          indexBy={({ date, partner }) => (view === "date" ? date : partner)}
          onClick={handleChangeView}
          tooltip={({ id, color, data }) => (
            <div
              style={{
                padding: 12,
                fontSize: `${fontSizeSmaller}rem`,
                color,
                background: "#222222",
              }}
            >
              {data[`${id}PerType`].map(({ slug, count }) => (
                <React.Fragment key={slug}>
                  {slug}: <strong>{count}</strong>
                  <br />
                </React.Fragment>
              ))}
            </div>
          )}
        />
      </Container>
    ) : (
      <div>no data</div>
    )}
  </>
);

const Container = styled.div(
  ({ theme }) => `
    width: 100%;
    height: 500px;
    padding-bottom: ${theme.spacingSmall}rem;
    position: relative;
`,
);

export default ActivitiesSection;
