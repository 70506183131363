import { jwtDecode } from "jwt-decode";
import refreshAccessToken from "../api/refreshAccessToken";
import * as storage from "./storage";

export const isTokenExpired = (token) => {
  const decodedToken = jwtDecode(token);
  const tokenExpiresAt = decodedToken.exp * 1000;
  return tokenExpiresAt < new Date().getTime();
};

export const loadAndValidateTokens = async () => {
  let accessToken = await storage.getAccessToken();
  let refreshToken = await storage.getRefreshToken();
  try {
    const tokensAreSet = accessToken !== null && refreshToken !== null;
    if (tokensAreSet) {
      if (isTokenExpired(accessToken)) {
        const response = await refreshAccessToken(refreshToken);
        if (response === null) {
          accessToken = null;
          refreshToken = null;
        } else {
          ({ accessToken } = response);
          ({ refreshToken } = response);
        }
        if (accessToken !== null && refreshToken !== null) {
          await storage.setAccessToken(accessToken);
          await storage.setRefreshToken(refreshToken);
        }
      }
    }
  } catch (error) {
    throw new Error(error);
  }
  return {
    refreshToken,
    accessToken,
  };
};
