import gql from "graphql-tag";

const resetOnboardingMutation = gql`
  mutation resetOnboarding($userId: Int!, $tenantId: Int!) {
    resetOnboarding(userId: $userId, tenantId: $tenantId) {
      tenantUser {
        userId
        accountStateSlug
      }
    }
  }
`;

export default async function resetOnboarding(
  apolloClient,
  userId,
  tenantId,
  options,
) {
  const mutation = resetOnboardingMutation;
  const variables = { userId, tenantId };
  const response = await apolloClient.mutate({
    mutation,
    variables,
    ...options,
  });
  return response.data.tenantUser;
}
