import { oneLineTrim } from "common-tags";

const {
  REACT_APP_ACTIVE_ACTIVE_DIRECTORY_AUTHORIZE_URL:
    ACTIVE_DIRECTORY_AUTHORIZE_URL,
  REACT_APP_ACTIVE_DIRECTORY_REDIRECT_URI: DIRECTORY_REDIRECT_URI,
  REACT_APP_ACTIVE_ACTIVE_DIRECTORY_CLIENT_ID: CLIENT_ID,
} = process.env;

export const composeActiveDirectoryUrl = () =>
  oneLineTrim`${ACTIVE_DIRECTORY_AUTHORIZE_URL}
        ?client_id=${CLIENT_ID}
        &redirect_uri=${encodeURIComponent(DIRECTORY_REDIRECT_URI)}
        &response_type=code
        &prompt=login
        &scope=${encodeURIComponent("api://3e8523ee-603a-43c3-a95a-935ba9fcd2db/Customers.Read.All offline_access")}
        &response_mode=query
    `;

export const loginWithActiveDirectory = () => {
  const href = composeActiveDirectoryUrl();
  window.location.href = href;
};
