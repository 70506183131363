import localforage from "localforage";
import mockDriver from "localforage-memoryStorageDriver";

// eslint-disable-next-line no-underscore-dangle
const MOCK_DRIVER = mockDriver._driver;

if (process.env.NODE_ENV === "test") {
  localforage.defineDriver(mockDriver);
  localforage.setDriver(MOCK_DRIVER);
}

const ACCESS_TOKEN = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";

// In memory cache
let accessToken = null;
let refreshToken = null;

export const cacheAccessToken = async () => {
  accessToken = await localforage.getItem(ACCESS_TOKEN);
};

export const getAccessToken = async () => {
  if (accessToken === null) {
    accessToken = await localforage.getItem(ACCESS_TOKEN);
  }
  return accessToken;
};

export const setAccessToken = async (newAccessToken) => {
  await localforage.setItem(ACCESS_TOKEN, newAccessToken);
  accessToken = newAccessToken;
};

export const removeAccessToken = async () => {
  await localforage.removeItem(ACCESS_TOKEN);
  accessToken = null;
};

export const cacheRefreshToken = async () => {
  refreshToken = await localforage.getItem(REFRESH_TOKEN_KEY);
};

export const getRefreshToken = async () => {
  if (refreshToken === null) {
    refreshToken = await localforage.getItem(REFRESH_TOKEN_KEY);
  }
  return refreshToken;
};

export const setRefreshToken = async (newRefreshToken) => {
  await localforage.setItem(REFRESH_TOKEN_KEY, newRefreshToken);
  refreshToken = newRefreshToken;
};

export const removeRefreshToken = async () => {
  await localforage.removeItem(REFRESH_TOKEN_KEY);
  refreshToken = null;
};
