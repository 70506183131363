import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import styled from "styled-components";
import useNotifications from "../../../hooks/useNotifications";
import PageWithState from "../../layout/PageWithState";
import {
  Active,
  ContentContainer,
  ContentTitle,
  Disabled,
} from "../tenants/TenantsPage";
import ActivitiesSectionWithState from "./ActivitiesSectionWithState";
import FocusedSectionWithState from "./FocusedSectionWithState";
import SyncSectionWithState from "./SyncSectionWithState";
import TenantDetailsSubscriptionSectionWithState from "./TenantDetailsSubscriptionSectionWithState";

const TenantDetailsPage = ({
  tenant,
  allProducts,
  focusedSettings,
  syncLogSummaries,
  analyticsPerDay,
  statisticsPerPartner,
  statisticsPerDate,
  analyticsKeys,
  refetchQueries,
  isUploadingContacts,
  isUploadingCompanies,
  isUploadingProjects,
  isRevertingImport,
  isResettingAccountState,
  onResetAccountState,
  onUploadContacts,
  onUploadCompanies,
  onUploadProjects,
  onRevertImport,
  onDownloadContactsTemplate,
  onDownloadCompaniesTemplate,
  onDownloadProjectsTemplate,
  onDeleteTenant,
  onUpdateSubscription,
  ...props
}) => {
  const navigate = useNavigate();

  const { dispatchWarning } = useNotifications();

  const getFile = (elementId) => {
    const element = document.getElementById(elementId);
    if (element === null || typeof element.files[0] === "undefined")
      return false;
    return element.files[0];
  };

  const [deleteText, setDeleteText] = useState("");
  const [deleteModal, showDeleteModal] = useState(false);

  return (
    <PageWithState {...props}>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => navigate("/tenants")}>
          Tenants
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{tenant.name}</Breadcrumb.Item>
      </Breadcrumb>
      <ContentContainer fluid>
        <ContentTitle>Overview</ContentTitle>
        <Form>
          <Form.Label>Name</Form.Label>
          <StyledInput disabled size="sm" type="text" value={tenant.name} />

          <Row>
            <Col>
              <Form.Label>ID</Form.Label>
              <StyledInput disabled size="sm" type="text" value={tenant.id} />
            </Col>
            <Col>
              <Form.Label>GUID</Form.Label>
              <StyledInput disabled size="sm" type="text" value={tenant.guid} />
            </Col>
          </Row>
        </Form>
      </ContentContainer>
      <ContentContainer fluid>
        <SyncSectionWithState
          syncLogSummaries={syncLogSummaries}
          analyticsPerDay={analyticsPerDay}
          analyticsKeys={analyticsKeys}
        />
      </ContentContainer>
      <ContentContainer fluid>
        <ActivitiesSectionWithState
          statisticsPerPartner={statisticsPerPartner}
          statisticsPerDate={statisticsPerDate}
        />
      </ContentContainer>
      <ContentContainer fluid>
        <ContentTitle>Users</ContentTitle>
        <Table responsive="lg" hover borderless size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>First name</th>
              <th>Name</th>
              <th>Login</th>
              <th>Terms and conditions</th>
              <th colSpan={2}>Account state</th>
            </tr>
          </thead>
          <tbody>
            {tenant.tenantUsers.map((tenantUser, index) => (
              <tr key={tenantUser.user.id}>
                <td>{index + 1}</td>
                <td>{tenantUser.user.signUpFirstName}</td>
                <td>{tenantUser.user.signUpName}</td>
                <td>{tenantUser.user.loginEmail}</td>
                <td>{tenantUser.user.termsAndConditionsAcceptedOn}</td>
                <td>{tenantUser.accountStateSlug}</td>
                <td>
                  <Button
                    size="sm"
                    variant="warning"
                    onClick={() => onResetAccountState(tenantUser.user.id)}
                  >
                    {isResettingAccountState === tenantUser.user.id ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Resetting...
                      </>
                    ) : (
                      "Reset"
                    )}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ContentContainer>
      <ContentContainer fluid>
        <FocusedSectionWithState
          tenantGuid={tenant.guid}
          focusedSettings={focusedSettings}
          tenantUsers={tenant.tenantUsers.map(({ user }) => user)}
          refetchQueries={refetchQueries}
        />
      </ContentContainer>
      <ContentContainer fluid>
        <ContentTitle>Sync</ContentTitle>
        {tenant.isSentMailsSyncActive ? (
          <Active label="mail" />
        ) : (
          <Disabled label="mail" />
        )}{" "}
        {tenant.isCalendarSyncActive ? (
          <Active label="calendar" />
        ) : (
          <Disabled label="calendar" />
        )}{" "}
        {tenant.isCallRecordSyncActive ? (
          <Active label="phone" />
        ) : (
          <Disabled label="phone" />
        )}
      </ContentContainer>
      <ContentContainer fluid>
        <ContentTitle>Stripe</ContentTitle>
        {tenant.subscription ? (
          <TenantDetailsSubscriptionSectionWithState
            allProducts={allProducts}
            stripeCustomerId={tenant.stripeCustomerId}
            subscription={tenant.subscription}
            currentPaymentMethod={tenant.currentPaymentMethod}
            onUpdateSubscription={onUpdateSubscription}
            isDemo={tenant.isDemo}
          />
        ) : (
          <p>No subscription found</p>
        )}
      </ContentContainer>
      <ContentContainer fluid>
        <ContentTitle>Import</ContentTitle>
        <Form>
          <Row>
            <Col>
              <Form.Control type="file" id="contacts-file" />
              <UploadButton
                onClick={onDownloadContactsTemplate}
                size="sm"
                variant="light"
              >
                Template
              </UploadButton>
              <UploadButton
                size="sm"
                id="contacts-upload-button"
                onClick={() => {
                  if (getFile("contacts-file")) {
                    onUploadContacts(tenant.guid, getFile("contacts-file"));
                  } else {
                    dispatchWarning("Please select a file");
                  }
                }}
                disabled={isUploadingContacts}
              >
                {isUploadingContacts ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Uploading contacts...
                  </>
                ) : (
                  "Upload contacts"
                )}
              </UploadButton>
            </Col>
            <Col>
              <Form.Control type="file" id="companies-file" />
              <UploadButton
                onClick={onDownloadCompaniesTemplate}
                size="sm"
                variant="light"
              >
                Template
              </UploadButton>
              <UploadButton
                size="sm"
                onClick={() => {
                  if (getFile("companies-file")) {
                    onUploadCompanies(tenant.guid, getFile("companies-file"));
                  } else {
                    dispatchWarning("Please select a file");
                  }
                }}
                disabled={isUploadingCompanies}
              >
                {isUploadingCompanies ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Uploading companies...
                  </>
                ) : (
                  "Upload companies"
                )}
              </UploadButton>
            </Col>
            <Col>
              <Form.Control type="file" id="projects-file" />
              <UploadButton
                onClick={onDownloadProjectsTemplate}
                size="sm"
                variant="light"
              >
                Template
              </UploadButton>
              <UploadButton
                size="sm"
                onClick={() => {
                  if (getFile("projects-file")) {
                    onUploadProjects(tenant.guid, getFile("projects-file"));
                  } else {
                    dispatchWarning("Please select a file");
                  }
                }}
                disabled={isUploadingProjects}
              >
                {isUploadingProjects ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Uploading projects...
                  </>
                ) : (
                  "Upload projects"
                )}
              </UploadButton>
            </Col>
          </Row>
        </Form>
      </ContentContainer>
      {tenant.dataImports.length > 0 && (
        <ContentContainer fluid>
          <ContentTitle>Import history</ContentTitle>
          <Table responsive="lg" hover borderless size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Filename</th>
                <th>createdOn</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {tenant.dataImports.map((dataImport, index) => (
                <tr key={dataImport.id}>
                  <td>{index + 1}</td>
                  <td>{dataImport.fileName}</td>
                  <td>{dataImport.createdOn}</td>
                  <td>
                    {dataImport.reverted ? (
                      "Reverted"
                    ) : (
                      <Button
                        onClick={() => onRevertImport(dataImport.guid)}
                        size="sm"
                        variant="warning"
                      >
                        {isRevertingImport === dataImport.guid ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Reverting...
                          </>
                        ) : (
                          "Revert"
                        )}
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ContentContainer>
      )}
      <ContentContainer fluid>
        <ContentTitle>Delete tenant</ContentTitle>
        <Form.Label>Type in the tenant GUID to delete this tenant</Form.Label>
        <StyledInput
          size="sm"
          type="text"
          value={deleteText}
          onChange={(e) => setDeleteText(e.target.value)}
        />
        <Button
          variant="danger"
          disabled={deleteText !== tenant.guid}
          onClick={() => showDeleteModal(true)}
        >
          Delete
        </Button>
      </ContentContainer>
      <Modal show={deleteModal} onHide={() => showDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete <b>{tenant.name}</b>?
          </p>
          <p>This can take some time to complete...</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => showDeleteModal(false)}>
            NO - Close
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              onDeleteTenant();
              showDeleteModal(false);
            }}
          >
            YES - Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </PageWithState>
  );
};

const StyledInput = styled(Form.Control)(
  ({ theme }) => `
    margin-bottom: ${theme.spacingSmaller}rem;
`,
);

const UploadButton = styled(Button)(
  ({ theme }) => `
    margin-top: ${theme.spacingSmaller}rem;
    margin-right: ${theme.spacingSmaller}rem;
`,
);

export default TenantDetailsPage;
