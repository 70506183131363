import React from "react";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import AppRouterWithState from "./AppRouterWithState";
import NotificationToaster from "./components/util/NotificationToaster";
import useAppState from "./hooks/useAppState";
import NotificationsProvider from "./providers/NotificationsProvider";
import ThemeProvider from "./providers/ThemeProvider";
import AppGlobalStyles from "./styles/AppGlobalStyles";

const App = ({ history, apolloClient, notificationDispatcher }) => {
  const { isAuthenticated, isLoadingInitialData } = useAppState();

  return (
    <BrowserRouter history={history}>
      <ApolloProvider client={apolloClient}>
        <NotificationsProvider dispatcher={notificationDispatcher}>
          <ThemeProvider>
            <NotificationToaster />
            <AppGlobalStyles />
            <AppRouterWithState
              isAuthenticated={isAuthenticated}
              isLoadingInitialData={isLoadingInitialData}
            />
          </ThemeProvider>
        </NotificationsProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default App;
