import { InMemoryCache, ApolloClient } from "@apollo/client";
import { createHttpLink } from "@apollo/client/link/http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { loadAndValidateTokens } from "../util/auth";

const { REACT_APP_GRAPHQL_URI: GRAPHQL_URI } = process.env;

const setAuthContext = async (request, { headers }) => {
  const { accessToken } = await loadAndValidateTokens();
  if (accessToken === null) {
    return { headers };
  }
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

const errorLink = onError(
  ({ response, operation, networkError, graphQLErrors }) => {
    const environment = process.env.NODE_ENV;
    const isDev = environment === "development";

    if (operation.operationName === "IgnoreErrorsQuery") {
      response.errors = null;
    }

    if (graphQLErrors) {
      if (isDev) {
        // eslint-disable-next-line no-console
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      }
    }

    if (networkError) {
      if (isDev) {
        // eslint-disable-next-line no-console
        console.log(`[Network error]: ${networkError}`);
      }
    }
  },
);

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
  credentials: "include",
});

const setAuthorizationHeadersLink = setContext(setAuthContext);

const linkFlow = setAuthorizationHeadersLink.concat(errorLink.concat(httpLink));

export default function createApolloClient() {
  return new ApolloClient({
    link: linkFlow,
    cache: new InMemoryCache(),
  });
}
