import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import AddTenantIcon from "../../svg/AddTenantIcon";
import ProfileIcon from "../../svg/ProfileIcon";
import DatabaseIcon from "../../svg/DatabaseIcon";
import DashboardIcon from "../../svg/DashboardIcon";
import { TOPBAR_HEIGHT } from "./Topbar";

export const SIDEBAR_WIDTH = 7;

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Container>
      <Menu>
        <MenuItem
          onClick={() => navigate("/dashboard")}
          isActive={
            location.pathname === "/dashboard" || location.pathname === "/"
          }
        >
          <DashboardIcon />
          Dashboard
        </MenuItem>
        <MenuItem
          onClick={() => navigate("/tenants")}
          isActive={location.pathname === "/tenants"}
        >
          <DatabaseIcon />
          Tenants
        </MenuItem>
        <MenuItem
          onClick={() => navigate("/add-tenant")}
          isActive={location.pathname === "/add-tenant"}
        >
          <AddTenantIcon />
          Add tenant
        </MenuItem>
        <MenuItem
          onClick={() => navigate("/profile")}
          isActive={location.pathname === "/profile"}
        >
          <ProfileIcon />
          Profile
        </MenuItem>
      </Menu>
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => `
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding-top: ${TOPBAR_HEIGHT}rem;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
    background: white;
    z-index: ${theme.sidebarZIndex};
`,
);

const Menu = styled.ul`
  padding-inline-start: 0;
`;

const MenuItem = styled.li(
  ({ theme, isActive, isDisabled }) => `
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: ${SIDEBAR_WIDTH}rem;
    font-size: ${theme.fontSizeSmaller}rem;
    color: ${isActive ? theme.colorBlueDark : theme.colorBlack};
    stroke: ${isActive ? theme.colorBlueDark : theme.colorBlack};
    fill: ${isActive ? theme.colorBlueDark : theme.colorBlack};
    border-bottom: 1px solid ${theme.colorGreyLighter};
    padding-top: ${theme.spacingSmaller}rem;
    padding-bottom: ${theme.spacingSmaller}rem;
    cursor: ${isDisabled ? "not-allowed" : "pointer"};
    ${
      !isDisabled &&
      `
        :hover {
            color: ${theme.colorBlueDark};
            stroke: ${theme.colorBlueDark};
            fill: ${theme.colorBlueDark};
        }`
    }
    ${
      isActive &&
      `
        :after {
            content: ' ';
            background: ${theme.colorBlueDark};
            position: absolute;
            width: 6px;
            height: 100%;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }}
    `
    }
`,
);

export default Sidebar;
