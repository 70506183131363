import gql from "graphql-tag";

export const REVERT_DATA_IMPORT_MUTATUION = gql`
  mutation revertDataImport($dataImportGuid: String!) {
    revertDataImport(dataImportGuid: $dataImportGuid)
  }
`;

export default async function revertDataImport(
  apolloClient,
  dataImportGuid,
  options,
) {
  const mutation = REVERT_DATA_IMPORT_MUTATUION;
  const variables = { dataImportGuid };
  const response = await apolloClient.mutate({
    mutation,
    variables,
    ...options,
  });
  return response.data;
}
