import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

const updateSubscriptionMutation = gql`
  mutation updateSubscription(
    $tenantGuid: String!
    $input: UpdateSubscriptionInput!
  ) {
    updateSubscription(tenantGuid: $tenantGuid, input: $input)
  }
`;

export default function useUpdateSubscriptionMutation() {
  return useMutation(updateSubscriptionMutation);
}
