import useFormState from "../../../hooks/useFormState";

const INITIAL_VALUES = {
  key: "alldayevents",
  example: "true",
  dataTypeSlug: "bool",
  value: false,
  userId: null,
};

const toFormValues = ({ key, example, dataTypeSlug, value, userId }) => ({
  key,
  example,
  dataTypeSlug,
  value: value === null ? "" : value,
  userId,
});

const valuesToInput = ({ key, value, userId }) => ({
  key,
  value: value === "" ? null : value.toString(),
  userId,
});

const useFocusedSettingForm = (values, options) => {
  const formState = useFormState(
    toFormValues({
      ...INITIAL_VALUES,
      ...values,
    }),
    {
      valuesToInput,
      ...options,
    },
  );

  const handleChangeSetting = (setting) => {
    formState.setValues({
      key: setting.key,
      example: setting.example,
      dataTypeSlug: setting.dataTypeSlug,
      value: undefined,
    });
  };

  return {
    ...formState,
    handleChangeSetting,
  };
};

export default useFocusedSettingForm;
