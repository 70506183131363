import React from "react";

const AddTenantIcon = () => (
  <svg
    height="32"
    width="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeLinecap="round" strokeWidth="1.2" transform="translate(0.5 0.5)">
      <polyline fill="none" points="15,31 4,31 4,12 14,2 24,12 24,19 " />
      <circle cx="14" cy="12" fill="none" r="2" />
      <line fill="none" x1="24" x2="24" y1="23" y2="27" />
      <line fill="none" x1="22" x2="26" y1="25" y2="25" />
      <circle cx="24" cy="25" fill="none" r="6" />
    </g>
  </svg>
);

export default AddTenantIcon;
