import { differenceInCalendarDays } from "date-fns";
import useFormState, { createFormValidation } from "use-form-state";
import { formatDateString } from "../../../util/dates";

const toInitialValues = ({
  quantity,
  trialEnd,
  plan,
  allowSecibNeoReporting,
  isDemo
}) => ({
  quantity,
  trialEnd: trialEnd && trialEnd?.length > 0 ? formatDateString(trialEnd, "yyyy-MM-dd") : '',
  planId: plan.id,
  allowSecibNeoReporting,
  isDemo
});

const validation = createFormValidation([
  {
    path: "trialEnd",
    validate: (value) => new Date(value) > new Date(),
    message: "Date must be after now",
  },
]);

const valuesToInput = ({
  quantity,
  trialEnd,
  planId,
  allowSecibNeoReporting,
  isDemo
}) => {
  const differenceInDays = trialEnd && trialEnd?.length > 0 ? differenceInCalendarDays(
    new Date(trialEnd),
    new Date(),
  ) : 0 ;
  const newTrialInDays = differenceInDays > 0 ? differenceInDays : 0;
  return {
    planId,
    quantity: parseInt(quantity),
    newTrialInDays,
    allowSecibNeoReporting,
    isDemo
  };
};

const useUpdateSubscriptionFormState = (initialValues) =>
  useFormState(toInitialValues(initialValues), { valuesToInput, validation });

export default useUpdateSubscriptionFormState;
