import React from "react";
import styled from "styled-components";
import Content from "../containers/navs/Content";
import Sidebar from "../containers/navs/Sidebar";
import Topbar from "../containers/navs/Topbar";

const Page = ({
  project,
  refetchQueries,
  onSearch,
  onAddEntityComplete,
  children,
  ...props
}) => (
  <AppContainer {...props}>
    <Topbar />
    <Sidebar />
    <Content>{children}</Content>
  </AppContainer>
);

const AppContainer = styled.div`
  height: 100vh;
`;

export default Page;
