import { AUTHORIZATION_PASSWORD_FORGOTTEN_CANCELED_CODE as PASSWORD_FORGOTTEN_CANCELED } from "./constants";
import { loginWithActiveDirectory } from "./activeDirectory";
import { parseQueryString } from "./tools";

const handleAuthorizationUrl = (url) => {
  const {
    code,
    error,
    state,
    error_description: errorDescription,
  } = parseQueryString(url);

  // TODO: Remove this check and pass isPasswordReset = true and code to login mutation
  if (state === "pw_reset") {
    loginWithActiveDirectory();
  } else if (typeof error === "undefined") {
    return { code };
  } else if (errorDescription.search(PASSWORD_FORGOTTEN_CANCELED) !== -1) {
    loginWithActiveDirectory();
  }
  throw new Error(error);
};

export default handleAuthorizationUrl;
