import React from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import PageWithState from "../../layout/PageWithState";

const LoadingPage = () => (
  <PageWithState>
    <Modal centered show backdrop="static" size="sm" keyboard="false">
      <ProgressBar striped animated now={100} />
    </Modal>
  </PageWithState>
);

export default LoadingPage;
