export const colorWhite = "#FFFFFF";
export const colorBlack = "#181817";

export const colorSalmonLightest = "#FDF1F0";
export const colorSalmonLighter = "#F5C4BE";
export const colorSalmonLight = "#ED978B";
export const colorSalmon = "#E56A59";
export const colorSalmonDark = "#B85548";

export const colorBlueLight = "#359BE0";
export const colorBlueDark = "#145388";

export const colorRed = "#FF7B68";

export const colorGreen = "#4bd285";

export const colorYellow = "#ffe066";

export const colorSuccess = "#def2d6";
export const colorWarning = "#f8f3d6";
export const colorError = "#ebc8c4";

export const colorPrimaryLightest = colorSalmonLightest;
export const colorPrimaryLighter = colorSalmonLighter;
export const colorPrimaryLight = colorSalmonLight;
export const colorPrimary = colorSalmon;
export const colorPrimaryDark = colorSalmonDark;
export const colorPrimaryAccent = colorBlueDark;

export const colorGreyWhite = "#f7fafc";
export const colorGreyLightest = "#edf2f7";
export const colorGreyLighter = "#e2e8f0";
export const colorGreyLight = "#cbd5e0";
export const colorGrey = "#a0aec0";
export const colorGreyDark = "#718096";
export const colorGreyDarker = "#4a5568";
export const colorGreyDarkest = "#2d3748";
export const colorGreyBlack = "#1a202c";

export const colorActive = colorGreen;
export const colorArchived = colorSalmonDark;

export const chartColors = [
  "#7eb0d5",
  "#fd7f6f",
  "#b2e061",
  "#bd7ebe",
  "#ffb55a",
  "#ffee65",
  "#beb9db",
  "#fdcce5",
  "#8bd3c7",
  "#86BBD8",
  "#D0A98F",
  "#93A8AC",
];

export const spacingMinimum = 0.1;
export const spacingSmallest = 0.5;
export const spacingSmaller = 1;
export const spacingSmall = 1.5;
export const spacing = 2;
export const spacingLarge = 3;
export const spacingLarger = 4;
export const spacingLargest = 5;

export const borderRadius = 0.5;
export const borderRadiusLarge = 1;
export const borderRadiusLarger = 1.5;

export const borderWidth = 0.1;
export const borderWidthLarge = 0.2;
export const borderWidthLarger = 0.4;

export const fontSizeSmallest = 0.6;
export const fontSizeSmaller = 0.8;
export const fontSizeSmall = 1;
export const fontSize = 1.2;
export const fontSizeLarge = 1.4;
export const fontSizeLarger = 1.8;
export const fontSizeLargest = 2.2;

export const lineHeightLarge = 1.9;

export const fontWeightThin = 100;
export const fontWeightExtraLight = 200;
export const fontWeightLight = 300;
export const fontWeightRegular = 400;
export const fontWeightMedium = 500;
export const fontWeightSemiBold = 600;
export const fontWeightBold = 700;
export const fontWeightExtraBold = 800;
export const fontWeightBlack = 900;

export const boxShadow = "0 2px 4px 0 rgba(0,0,0,0.10)";
export const boxShadowLarge =
  "0 4px 8px 0 rgba(0,0,0,0.12),0 2px 4px 0 rgba(0,0,0,0.08)";
export const boxShadowLarger =
  "0 15px 30px 0 rgba(0,0,0,0.11),0 5px 15px 0 rgba(0,0,0,0.08)";

export const maxWidth = 102.4;

export const defaultSpacing = spacing;

export const defaultBackgroundColor = colorGreyLightest;

export const defaultFontFamily =
  '"Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
export const numberFontFamily = '"Lucida Console", Monaco, monospace';
export const defaultFontWeight = fontWeightExtraLight;
export const defaultFontSize = fontSize;
export const defaultFontColor = colorGreyDarker;
export const defaultFontColorDark = colorGreyBlack;
export const defaultFontColorLight = colorGrey;
export const defaultFontColorDisabled = colorGreyLight;
export const defaultLineHeight = 1.5;
export const defaultLetterSpacing = 0;

export const defaultBorderColor = colorGreyLighter;
export const defaultBorderWidth = borderWidth;
export const defaultBorderRadius = borderRadius;

export const defaultIconColor = colorBlack;
export const defaultIconInnerColor = colorBlack;

export const defaultButtonBorderRadius = borderRadius;

export const iconSizeSmallest = 8;
export const iconSizeSmaller = 12;
export const iconSizeSmall = 16;
export const iconSize = 22;
export const iconSizeLarge = 26;
export const iconSizeLarger = 32;
export const iconSizeLargest = 60;

export const modalSizeSmall = 40;
export const modalSize = 55;
export const modalSizeLarge = 70;
export const modalSizeLarger = 90;

export const defaultNavigationZIndex = 1;
export const sidebarZIndex = 2;
export const topbarZIndex = 3;
