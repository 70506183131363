/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import styled from "styled-components";
import { Alert, Badge, ProgressBar } from "react-bootstrap";
import { chartColors, fontSizeSmaller } from "../../../styles/theme";
import { ContentTitle } from "../tenants/TenantsPage";
import {
  DEFAULT_FORMAT_DATE_STRING,
  DEFAULT_FORMAT_TIME_STRING,
  formatDateString,
} from "../../../util/dates";

export const BAR_PROPS = {
  margin: {
    top: 50,
    right: 150,
    bottom: 50,
    left: 50,
  },
  padding: 0.2,
  colors: chartColors,
  legends: [
    {
      dataFrom: "keys",
      anchor: "bottom-right",
      direction: "column",
      justify: false,
      translateX: 120,
      translateY: 0,
      itemsSpacing: 2,
      itemWidth: 100,
      itemHeight: 20,
      itemDirection: "left-to-right",
      toggleSerie: true,
    },
  ],
  labelSkipWidth: 12,
  labelSkipHeight: 12,
};

const SyncSection = ({
  view,
  syncLogSummaries,
  analyticsData,
  analyticsKeys,
  handleChangeView,
}) => (
  <>
    <ContentTitle>Sync summary</ContentTitle>
    {syncLogSummaries.length > 0 ? (
      <FlexBox>
        {syncLogSummaries.map(
          ({
            initialSyncStart,
            initialSyncEnd,
            processedUnits,
            totalUnits,
            totalCount,
            entitiesPerUnit,
            key,
          }) => {
            const variantColor =
              processedUnits === totalUnits || totalUnits === null
                ? "success"
                : "warning";
            if (initialSyncEnd) {
              return (
                <CardContainer key={key}>
                  <Alert variant={variantColor}>
                    <CardHeader>{key}</CardHeader>
                    <AlertText>
                      Initial sync
                      <StyledBadge bg="success" text="light">
                        done
                      </StyledBadge>
                    </AlertText>
                    <ProgressBar
                      now={100}
                      label={
                        totalUnits
                          ? `${totalCount} / ${totalCount}`
                          : totalCount
                      }
                      striped
                    />
                  </Alert>
                </CardContainer>
              );
            }
            return (
              <CardContainer key={key}>
                <Alert variant={variantColor}>
                  <CardHeader>
                    {key}
                    <StyledBadge bg="warning" text="light">
                      Syncing...
                    </StyledBadge>
                  </CardHeader>
                  <AlertText>
                    Initial sync (
                    {formatDateString(
                      initialSyncStart,
                      `${DEFAULT_FORMAT_DATE_STRING} ${DEFAULT_FORMAT_TIME_STRING}`,
                    )}
                    )<StyledBadge variant="danger">busy</StyledBadge>
                  </AlertText>
                  <ProgressBar
                    now={(processedUnits / totalUnits) * 100}
                    label={`${processedUnits * entitiesPerUnit} / ${totalUnits * entitiesPerUnit}`}
                    striped
                  />
                </Alert>
              </CardContainer>
            );
          },
        )}
      </FlexBox>
    ) : (
      <div>
        no data <br />
        <br />
      </div>
    )}
    <ContentTitle>
      Synced items {view === "week" ? "last week" : view}
    </ContentTitle>
    {analyticsData.filter(({ hour, perHour }) => hour || perHour.length > 0)
      .length > 0 ? (
      <Container>
        <ResponsiveBar
          {...BAR_PROPS}
          key={view}
          data={analyticsData}
          keys={analyticsKeys}
          indexBy={({ date, hour }) => (view === "week" ? date : hour)}
          onClick={handleChangeView}
          tooltip={({ id, value, indexValue, color }) => (
            <div
              style={{
                padding: 12,
                fontSize: `${fontSizeSmaller}rem`,
                color,
                background: "#222222",
              }}
            >
              {indexValue}
              <br />
              <strong>
                {id}: {value}
              </strong>
            </div>
          )}
        />
      </Container>
    ) : (
      <div>no data</div>
    )}
  </>
);

const Container = styled.div(
  ({ theme }) => `
    width: 100%;
    height: 500px;
    padding-bottom: ${theme.spacingSmall}rem;
`,
);

const FlexBox = styled.div(
  ({ theme }) => `
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
    padding-bottom: ${theme.spacing}rem;
`,
);

const CardContainer = styled.div`
  min-width: 17rem;
`;

const CardHeader = styled.div(
  ({ theme }) => `
    font-size: ${theme.fontSizeSmall}rem;
    padding-bottom: ${theme.spacingSmallest}rem;
`,
);

const AlertText = styled.div(
  ({ theme }) => `
    font-size: ${theme.fontSizeSmaller}rem;
    padding-bottom: ${theme.spacingSmallest}rem;
`,
);

const StyledBadge = styled(Badge)`
  float: right;
`;

export default SyncSection;
