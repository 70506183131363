import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { endOfToday, subDays } from "date-fns";
import { formatDate, formatDateString } from "../../../util/dates";

const GLOBAL_ACTIVITY_STATISTICS = gql`
  query globalActivityStatistics($where: ActivityWhereInput) {
    globalActivityStatistics(where: $where) {
      perCreationDay {
        creationDate
        perMLStatus {
          status
          count
          perType {
            type {
              slug
            }
            count
          }
        }
      }
    }
  }
`;

const useFetchDashboardPageData = (tenantGuid) => {
  const dates = [endOfToday()];
  for (let i = 1; i < 7; i += 1) {
    dates.push(subDays(dates[0], i));
  }
  const variables = {
    where: {
      startDateTime: dates[6],
      endDateTime: dates[0],
    },
  };
  const {
    data = {},
    error,
    loading,
  } = useQuery(GLOBAL_ACTIVITY_STATISTICS, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  const refetchQueries = [{ query: GLOBAL_ACTIVITY_STATISTICS, variables }];

  if (loading) {
    return {
      isFetching: loading,
      error,
      globalActivityStatistics: [],
    };
  }

  const statisticsPerDate = data.globalActivityStatistics.perCreationDay.reduce(
    (arr, { creationDate, perMLStatus }) => [
      ...arr,
      {
        date: formatDateString(creationDate),
        ...perMLStatus.reduce((acc, item) => {
          acc[item.status] = item.count;
          acc[`${item.status}PerType`] = item.perType.map(
            ({ type, count }) => ({ slug: type.slug, count }),
          );
          return acc;
        }, {}),
      },
    ],
    [],
  );

  const statisticsPerDateWithEmptyDays = dates
    .map((date) => {
      const result = statisticsPerDate.find(
        (stats) => formatDate(date) === stats.date,
      );
      if (result) return result;
      return {
        date: formatDate(date),
      };
    })
    .reverse();

  return {
    isFetching: loading,
    error,
    globalActivityStatistics: statisticsPerDateWithEmptyDays,
    refetchQueries,
  };
};

export default useFetchDashboardPageData;
