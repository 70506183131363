import { useReducer } from "react";

const OPEN_MODAL = "OPEN_MODAL";
const CLOSE_MODAL = "CLOSE_MODAL";
const UPDATE = "UPDATE";

const createReducer = () => (state, action) => {
  const { type, initialState } = action;
  switch (type) {
    case OPEN_MODAL:
      return {
        isOpen: true,
        initialState: {
          ...state.initialState,
          ...initialState,
        },
      };
    case UPDATE:
      return {
        ...state,
        initialState: {
          ...state.initialState,
          ...initialState,
        },
      };
    case CLOSE_MODAL:
      return {
        isOpen: false,
        initialState,
      };
    default:
      throw new Error();
  }
};

const useModalState = (initialState = {}, isOpen = false) => {
  const [state, dispatch] = useReducer(createReducer(), {
    isOpen,
    initialState,
  });

  const open = (newState = {}) =>
    dispatch({
      type: OPEN_MODAL,
      initialState: newState,
    });

  const update = (newState = {}) =>
    dispatch({
      type: UPDATE,
      initialState: newState,
    });

  const close = () =>
    dispatch({
      type: CLOSE_MODAL,
      initialState,
    });

  return {
    ...state,
    open,
    close,
    update,
  };
};

export default useModalState;
