import { useReducer } from "react";
import dotProp from "dot-prop-immutable";
import clonedeep from "lodash.clonedeep";
import isequal from "lodash.isequal";
import useDeepCompareEffect from "use-deep-compare-effect";

const containsNoErrors = (errors) => errors.length === 0;

const getValuesToValidate = (formValues, validationPaths) => {
  if (!validationPaths.length) {
    return formValues;
  }
  return validationPaths.reduce((obj, field) => {
    const newObj = obj;
    if (typeof formValues[field] !== "undefined") {
      newObj[field] = formValues[field];
    }
    return newObj;
  }, {});
};

const INITIAL_STATE = {
  values: {},
  errors: [],
  isDirty: false,
  isPristine: true,
  isValid: false,
  validationPaths: [],
};

const SET_STATE = "SET_STATE";
const SET_VALUE = "SET_VALUE";
const UPDATE_VALUES = "UPDATE_VALUES";
const UPDATE_ERRORS = "UPDATE_ERRORS";
const VALIDATE = "VALIDATE";
const RESET_FORM = "RESET_FORM";
const SET_VALIDATION = "SET_VALIDATION";

const reducer = (state, action) => {
  const { validation, validationOptions } = state;
  switch (action.type) {
    case SET_VALUE: {
      const { key, value } = action;
      let newState = dotProp.set(state, `values.${key}`, value);
      let { isValid } = state;
      if (!newState.isPristine) {
        const { validationPaths } = state;
        const valuesToValidate = getValuesToValidate(
          newState.values,
          validationPaths,
        );
        const newErrors = validation(valuesToValidate, validationOptions);
        newState = dotProp.set(newState, "errors", newErrors);
        isValid = containsNoErrors(newErrors);
      }
      return {
        ...newState,
        isValid,
        isDirty: true,
      };
    }
    case SET_VALIDATION: {
      return {
        ...state,
        validation: action.validation,
      };
    }
    case UPDATE_VALUES: {
      const { values } = action;
      const newValues = {
        ...state.values,
        ...values,
      };
      let newState = dotProp.set(state, "values", newValues);
      let newErrors = [];

      if (!state.isPristine) {
        const { validationPaths } = state;
        const valuesToValidate = getValuesToValidate(
          newValues,
          validationPaths,
        );
        newErrors = validation(valuesToValidate, validationOptions);
      }
      const isValid = containsNoErrors(newErrors);
      newState = dotProp.set(newState, "errors", newErrors);
      return {
        ...newState,
        isValid,
        isDirty: true,
      };
    }
    case UPDATE_ERRORS: {
      const { errors } = action;
      return dotProp.set(state, "errors", errors);
    }
    case VALIDATE: {
      const { paths } = action;
      const valuesToValidate = getValuesToValidate(state.values, paths);
      const errors = validation(valuesToValidate, validationOptions);
      const isValid = containsNoErrors(errors);
      const newState = dotProp.set(state, "errors", errors);
      return {
        ...newState,
        validationPaths: paths,
        isPristine: false,
        isValid,
      };
    }
    case RESET_FORM: {
      const { initialValues, initialValidation } = action;
      const errors = [];
      return {
        ...INITIAL_STATE,
        values: initialValues,
        validation: initialValidation,
        errors,
      };
    }
    case SET_STATE: {
      return action.state;
    }
    default:
      throw new Error(`Unknown form state action '${action.type}'.`);
  }
};

const useFormState = (initialValues = {}, options = {}) => {
  const {
    validation: initialValidation = () => [],
    validationOptions: initialValidationOptions,
    valuesToInput,
    debug = false,
  } = options;

  const initialState = {
    ...INITIAL_STATE,
    values: initialValues,
    validation: initialValidation,
    validationOptions: initialValidationOptions,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  useDeepCompareEffect(() => {
    const initialValuesClone = clonedeep(initialValues);
    dispatch({
      type: SET_STATE,
      state: {
        ...INITIAL_STATE,
        values: initialValuesClone,
        validation: initialValidation,
        validationOptions: initialValidationOptions,
      },
    });
  }, [initialValues, initialValidationOptions]);

  if (debug) {
    // eslint-disable-next-line no-console
    console.log(state);
  }

  const resetForm = () => {
    dispatch({
      type: RESET_FORM,
      initialValues,
      initialValidation,
      initialValidationOptions,
      errors: [],
    });
  };

  const setErrors = (errors) => {
    dispatch({
      type: UPDATE_ERRORS,
      errors,
    });
  };

  const setValues = (values) => {
    dispatch({
      type: UPDATE_VALUES,
      values,
    });
  };

  const setValue = (key, value) => {
    dispatch({
      type: SET_VALUE,
      key,
      value,
    });
  };

  const handleNativeChange = (e) => {
    const { name, type, value, checked, files } = e.target;
    let finalValue = value;
    if (type === "checkbox") {
      finalValue = checked;
    } else if (type === "file") {
      [finalValue] = files;
    }
    setValue(name, finalValue);
  };

  const setValidation = (newValidation) => {
    dispatch({
      type: SET_VALIDATION,
      validation: newValidation,
    });
  };

  const validate = (paths = []) => {
    const { validation, validationOptions } = state;
    const valuesToValidate = getValuesToValidate(state.values, paths);
    const errors = validation(valuesToValidate, validationOptions);
    const isValid = containsNoErrors(errors);
    dispatch({
      type: VALIDATE,
      paths,
    });
    return isValid;
  };

  const getValue = (path) => dotProp.get(state.values, path, "");

  const getChanges = (paths = []) => {
    const updatedValues = {};
    paths.forEach((path) => {
      if (!isequal(state.values[path], initialValues[path])) {
        updatedValues[path] = state.values[path];
      }
    });
    return updatedValues;
  };

  const getErrorMessages = (path) =>
    state.errors
      .filter((error) => error.path.includes(path))
      .map(({ message }) => message);

  const hasError = (path) =>
    typeof state.errors.find((error) => error.path === path) !== "undefined";

  const getNativeInputProps = (path) => ({
    value: getValue(path),
    hasError: hasError(path),
    onChange: handleNativeChange,
    name: path,
  });

  const getInputProps = (path) => ({
    value: getValue(path),
    hasError: hasError(path),
    onChange: (value) => setValue(path, value),
    name: path,
  });

  return {
    errors: state.errors,
    values: state.values,
    updatedValues: state.changes,
    isDirty: state.isDirty,
    isValid: state.isValid,
    isPristine: state.isPristine,
    setValues,
    setValidation,
    handleChange: setValue,
    handleNativeChange,
    validate,
    updateErrors: setErrors,
    resetForm,
    getInputProps,
    getNativeInputProps,
    valuesToInput: () => valuesToInput(state.values),
    getValue,
    getChanges,
    getErrorMessages,
    hasError,
  };
};

export default useFormState;
