import React from "react";
import ReactDOM from "react-dom/client";
import AppWithState from "./AppWithState";
import createApolloClient from "./apollo/createApolloClient";
import "./styles/bootstrap.css";
import { getNotificationDispatcher } from "./util/notifications";

const apolloClient = createApolloClient();
const notificationDispatcher = getNotificationDispatcher();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppWithState
    apolloClient={apolloClient}
    notificationDispatcher={notificationDispatcher}
  />,
);
