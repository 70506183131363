import React from "react";
import { Nav } from "react-bootstrap";
import styled from "styled-components";
import Logo from "../../svg/Logo";

export const TOPBAR_HEIGHT = 3;

const Topbar = () => (
  <StyledNav>
    <LeftContainer>
      <Logo />
      <LogoDescription>lexor-backoffice</LogoDescription>
    </LeftContainer>
    <MiddleContainer />
    <RightContainer />
  </StyledNav>
);

const LeftContainer = styled.div`
  display: flex;
  flex-basis: 30%;
  align-items: center;
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-basis: 40%;
  justify-content: center;
  align-items: center;
`;

const RightContainer = styled.div(
  ({ theme }) => `
    display: flex;
    flex-basis: 30%;
    align-items: center;
    justify-content: flex-end;
    & > svg {
        padding-left: ${theme.spacingSmallest}rem;
        cursor: pointer;
    }
`,
);

const StyledNav = styled(Nav)(
  ({ theme }) => `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-left: ${theme.spacingSmall}rem;
    padding-right: ${theme.spacingSmall}rem;
    height: ${TOPBAR_HEIGHT}rem;
    z-index: 99;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
    background: ${theme.colorBlueDark};
`,
);

const LogoDescription = styled.h1(
  ({ theme }) => `
    font-size: ${theme.fontSizeSmall}rem;
    margin-left: ${theme.spacingSmallest}rem;
    color: ${theme.colorGreyLightest};
`,
);

export default Topbar;
