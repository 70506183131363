import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

const deleteActivityRelevancySettingsMutation = gql`
  mutation deleteActivityRelevancySetting(
    $tenantGuid: String!
    $key: String!
    $userId: Int!
  ) {
    deleteActivityRelevancySetting(
      tenantGuid: $tenantGuid
      key: $key
      userId: $userId
    )
  }
`;

export default function useDeleteFocusedSettingsMutation() {
  return useMutation(deleteActivityRelevancySettingsMutation);
}
