/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import React from "react";
import { Spinner } from "react-bootstrap";
import PageWithState from "../../layout/PageWithState";
import { ContentContainer } from "../tenants/TenantsPage";
import DashboardPage from "./DashboardPage";
import useFetchDashboardPageData from "./useFetchDashboardPageData";

const DashboardPageWithState = () => {
  const { isFetching, globalActivityStatistics } = useFetchDashboardPageData();

  if (isFetching) {
    return (
      <PageWithState>
        <ContentContainer fluid>
          <Spinner animation="border" variant="secondary" />
        </ContentContainer>
      </PageWithState>
    );
  }

  return (
    <DashboardPage
      data={globalActivityStatistics}
      keys={["none", "pending", "processed"]}
    />
  );
};

export default DashboardPageWithState;
