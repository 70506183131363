import gql from "graphql-tag";

const LoginMutation = gql`
  mutation login($code: String!, $callBackUrl: String!) {
    login(authorizationCode: $code, callBackUrl: $callBackUrl) {
      accessToken
      refreshToken
      me {
        email
        name
      }
    }
  }
`;

export default async function login(apolloClient, code, callBackUrl) {
  const mutation = LoginMutation;
  const variables = { code, callBackUrl };
  const response = await apolloClient.mutate({ mutation, variables });
  return response.data.login;
}
