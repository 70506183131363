import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import PageWithState from "../../layout/PageWithState";
import { ContentContainer, ContentTitle } from "../tenants/TenantsPage";
import AddTenantForm from "./AddTenantForm";

const AddTenantPage = ({
  products,
  tenantTypes,
  onSubmit,
  isSubmitting,
  newTenantInfo,
}) => (
  <PageWithState>
    <ContentContainer>
      <ContentTitle>Add tenant</ContentTitle>
      <AddTenantForm
        products={products}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        tenantTypes={tenantTypes}
      />
    </ContentContainer>
    {newTenantInfo && (
      <ContentContainer>
        <ContentTitle>New tenant</ContentTitle>
        <Form>
          <Form.Label>Email</Form.Label>
          <StyledInput
            readOnly
            size="sm"
            type="email"
            value={newTenantInfo.email}
          />
          <Form.Label>Password</Form.Label>
          <StyledInput
            readOnly
            size="sm"
            type="text"
            value={newTenantInfo.password}
          />
          <Form.Label>GUID</Form.Label>
          <StyledInput
            readOnly
            size="sm"
            type="text"
            value={newTenantInfo.tenantGuid}
          />
        </Form>
      </ContentContainer>
    )}
  </PageWithState>
);

const StyledInput = styled(Form.Control)(
  ({ theme }) => `
    margin-bottom: ${theme.spacingSmaller}rem;
`,
);

export default AddTenantPage;
