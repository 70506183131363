import * as storage from "../util/storage";

export default async function uploadContactsFile(tenantGuid, file) {
  const { REACT_APP_API_URI: API_URI } = process.env;

  const accessToken = await storage.getAccessToken();
  const bearer = `Bearer ${accessToken}`;

  const url = `${API_URI}/contacts/upload`;

  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      authorization: bearer,
      Tenant: tenantGuid,
    },
    body: formData,
  });

  return response;
}
