import gql from "graphql-tag";
import createSimpleApolloClient from "../apollo/createSimpleApolloClient";

const refreshAccessTokenMutation = gql`
  mutation refreshAccessTokenMutation($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

export default async function refreshAccessToken(refreshToken) {
  const apolloClient = createSimpleApolloClient();
  try {
    const mutation = refreshAccessTokenMutation;
    const variables = { refreshToken };
    const response = await apolloClient.mutate({
      mutation,
      variables,
      errorPolicy: "ignore",
    });
    return response.data.refreshAccessToken;
  } catch (error) {
    // if auth error
    // logout
    return null;
  }
}
