import React, { useState, useCallback } from "react";
import TenantsPage from "./TenantsPage";
import useFetchTenantsPageData from "./useFetchTenantsPageData";
import { debounce } from '../../../util/tools'

export const PAGE_SIZE = 20;

const TenantsPageWithState = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const { tenants, meta, isFetching } = useFetchTenantsPageData({
    currentPage,
    pageSize: PAGE_SIZE,
    search:{
        guidContains: searchTerm,
        nameContains: searchTerm
    }
  });



  const handleSearch = useCallback((query) => {
    setSearchTerm(query);
    setCurrentPage(1); // Reset to the first page on new search
  }, []);

  const debouncedSearch = useCallback(
    debounce((query) => {
      handleSearch(query);
    }, 1000),
    [handleSearch]
  );

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchInputValue(value)
    debouncedSearch(value);
  };

  const handleReset = () => {
    setSearchTerm("");
    setSearchInputValue("");
    handleSearch("");
  };
  return (   
    <>
      <TenantsPage
        isFetching={isFetching}
        tenants={tenants}
        meta={meta}
        currentPage={currentPage}
        onChangeCurrentPage={(page) => setCurrentPage(parseInt(page, 10))}
        searchInputValue={searchInputValue}
        searchTerm={searchTerm}
        handleChange={handleChange}
        handleReset={handleReset}
      />
    </>
  );
};

export default TenantsPageWithState;
