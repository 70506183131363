import React from "react";
import AppRouter from "./AppRouter";

const AppRouterWithState = ({
  isAuthenticated,
  isLoadingInitialData,
  ...props
}) => (
  <AppRouter
    isAuthenticated={isAuthenticated}
    isLoadingInitialData={isLoadingInitialData}
    {...props}
  />
);

export default AppRouterWithState;
