import React from "react";
import { Modal, Button } from "react-bootstrap";
import styled from "styled-components";
import { loginWithActiveDirectory } from "../../../util/activeDirectory";
import PageWithState from "../../layout/PageWithState";

const LoginPage = () => (
  <PageWithState>
    <Modal centered show backdrop="static" size="sm" keyboard="false">
      <Container>
        <h1>Access denied</h1>
        <p>Please log in</p>
        <p>
          <Button
            onClick={() => {
              loginWithActiveDirectory("b64ce421-2bf8-42dc-8e5d-4c6ec4728939");
            }}
          >
            Login
          </Button>
        </p>
      </Container>
    </Modal>
  </PageWithState>
);

const Container = styled.div(
  ({ theme }) => `
    padding: ${theme.spacing}rem;
`,
);

export default LoginPage;
