import * as storage from "../util/storage";

export default async function downloadContactsFile() {
  const { REACT_APP_API_URI: API_URI } = process.env;

  const accessToken = await storage.getAccessToken();
  const bearer = `Bearer ${accessToken}`;

  const url = `${API_URI}/contacts/template`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      authorization: bearer,
    },
  });

  return response;
}
