import React from "react";
import { Routes, Route } from "react-router-dom";
import AddTenantPageWithState from "./components/pages/add-tenant/AddTenantPageWithState";
import LoginPage from "./components/pages/login/LoginPage";
import AuthenticatingPage from "./components/pages/authenticating/AuthenticatingPage";
import ProfilePageWithState from "./components/pages/profile/ProfilePageWithState";
import LoadingPage from "./components/pages/loading/LoadingPage";
import TenantsPageWithState from "./components/pages/tenants/TenantsPageWithState";
import TenantDetailsPageWithState from "./components/pages/tenant-details/TenantDetailsPageWithState";
import DashboardPageWithState from "./components/pages/dashboard/DashboardPageWithState";

const AppRouter = ({ isAuthenticated, isLoadingInitialData, ...props }) => {
  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="*" element={<LoginPage {...props} />} />
        <Route path="/login" element={<LoginPage {...props} />} />
        <Route path="/authenticate" element={<AuthenticatingPage {...props} />} />
      </Routes>
    );
  }
  if (isLoadingInitialData) {
    return (
      <Routes>
        <Route path="/loading" element={<LoadingPage {...props} />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="*" element={<DashboardPageWithState {...props} />} />
      <Route path="/dashboard" element={<DashboardPageWithState  {...props}/>} />
      <Route path="/add-tenant" element={<AddTenantPageWithState {...props} />} />

      <Route path="/profile" element={<ProfilePageWithState {...props} />} />

      <Route path="/tenants" element={<TenantsPageWithState {...props} />} />

      <Route
        path="/tenant/:tenantGuid/*"
        element={<TenantDetailsPageWithState {...props} />}
      />
    </Routes>
  );
};

export default AppRouter;
