import React, { forwardRef } from "react";
import { Alert } from "react-bootstrap";
import styled from "styled-components";

const NotificationToasterItem = forwardRef(
  ({ data, onCloseNotification }, ref) => {
    const { message, variant } = data;
    return (
      <StyledToasterItem ref={ref}>
        <Alert variant={variant} onClose={onCloseNotification} dismissible>
          {message}
        </Alert>
      </StyledToasterItem>
    );
  },
);

const StyledToasterItem = styled.div`
  opacity: 0.5;
`;

export default NotificationToasterItem;
