import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFormState } from "react-use-form-state";
import styled from "styled-components";
import { isEmptyObject } from "../../../util/tools";

const AddTenantForm = ({ products, tenantTypes, onSubmit, isSubmitting }) => {
  const [formState, { text, email, select }] = useFormState({
    officeName: "",
    adminFirstName: "",
    adminName: "",
    adminPreferredCulture: "nl",
    adminGender: "x",
    officePlanStripeId: products.length && products[0]?.stripeId || '',
    voucherCode: "",
    tenantTypeId: tenantTypes.length && tenantTypes[0].id || '',
  });
  const valuesToInput = (values) => ({
    ...values,
    voucherCode: values.voucherCode === "" ? null : values.voucherCode,
    tenantTypeId: Number(values.tenantTypeId),
  });
  return (
    <Form>
      <Form.Label>Office name</Form.Label>
      <StyledInput
        size="sm"
        error={formState.errors.officeName}
        {...text({
          name: "officeName",
          validate: (value) => (value === "" ? "required" : null),
        })}
      />
      <Row>
        <Col>
          <Form.Label>Firstname</Form.Label>
          <StyledInput
            size="sm"
            error={formState.errors.adminFirstName}
            {...text({
              name: "adminFirstName",
              validate: (value) => (value === "" ? "required" : null),
            })}
          />
        </Col>
        <Col>
          <Form.Label>Name</Form.Label>
          <StyledInput
            size="sm"
            error={formState.errors.adminName}
            {...text({
              name: "adminName",
              validate: (value) => (value === "" ? "required" : null),
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Language</Form.Label>
            <Form.Control
              as="select"
              value="nl"
              error={formState.errors.adminPreferredCulture}
              {...select({
                name: "adminPreferredCulture",
                validate: (value) => (value === "" ? "required" : null),
              })}
            >
              <option value="nl">dutch</option>
              <option value="fr">french</option>
              <option value="en">english</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Form.Label>Email</Form.Label>
      <StyledInput
        size="sm"
        error={formState.errors.email}
        {...email({
          name: "email",
          validate: (value) => (value === "" ? "required" : null),
        })}
      />
      <Form.Label>Vouchercode</Form.Label>
      <StyledInput
        size="sm"
        error={formState.errors.voucherCode}
        {...text("voucherCode")}
      />
      <Form.Group controlId="plan">
        <Form.Label>Plan</Form.Label>
        <Form.Control
          as="select"
          value="nl"
          error={formState.errors.officePlanStripeId}
          {...select({
            name: "officePlanStripeId",
            validate: (value) => (value === "" ? "required" : null),
          })}
        >
          {products.map((product) => (
            <option value={product.stripeId} key={product.id}>
              {product.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="tenantTypeId" style={{ marginTop: "1rem" }}>
        <Form.Label>Tenant type</Form.Label>
        <Form.Control
          as="select"
          error={formState.errors.tenantTypeId}
          {...select({
            name: "tenantTypeId",
            validate: (value) => (value === "" ? "required" : null),
          })}
        >
          {tenantTypes.map((tenantType) => (
            <option value={tenantType.id} key={tenantType.id}>
              {tenantType.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <ConfirmContainer>
        <StyledButton variant="light" size="sm" onClick={formState.clear}>
          Reset
        </StyledButton>
        <StyledButton
          variant="success"
          size="sm"
          onClick={() => onSubmit(valuesToInput(formState.values))}
          disabled={
            isSubmitting ||
            formState.isPristine() ||
            !isEmptyObject(formState.errors)
          }
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </StyledButton>
      </ConfirmContainer>
    </Form>
  );
};

const StyledInput = styled(Form.Control)(
  ({ theme, error }) => `
    margin-bottom: ${theme.spacingSmallest}rem;
    ${
      error &&
      `
        border-color: ${theme.colorRed};
    `
    }
`,
);

const ConfirmContainer = styled.div(
  ({ theme }) => `
    margin-top: ${theme.spacingSmall}rem;
    display: flex;
    justify-content: flex-end;
`,
);

const StyledButton = styled(Button)(
  ({ theme }) => `
    margin-left: ${theme.spacingSmallest}rem;
`,
);

export default AddTenantForm;
