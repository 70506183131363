import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { capitalizeFirstLetter } from "../../../util/tools";

const ALL_PRODUCTS_QUERY = gql`
  query allProducts {
    allProducts {
      id
      slug
      stripeId
      plans {
        id
        name
        stripeId
      }
    }
  }
`;

const useFetchAddTenantData = () => {
  const { data = {}, error, loading } = useQuery(ALL_PRODUCTS_QUERY);

  const products = [];

  if (!loading && !error) {
    data.allProducts.forEach((product) => {
      product.plans.forEach((plan) => {
        products.push({
          name: `${capitalizeFirstLetter(product.slug)} (${plan.name})`,
          id: plan.id,
          stripeId: plan.stripeId,
        });
      });
    });
  }

  return {
    isFetching: loading,
    error,
    products,
  };
};

export default useFetchAddTenantData;
