/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import React, { useState } from "react";
import SyncSection from "./SyncSection";

export const PAGE_SIZE = 20;

const SyncSectionWithState = ({
  analyticsPerDay,
  analyticsKeys,
  syncLogSummaries,
}) => {
  const [view, setView] = useState("week");
  return (
    <SyncSection
      analyticsData={
        view === "week"
          ? analyticsPerDay
          : analyticsPerDay.find(({ date }) => date === view).perHour
      }
      analyticsKeys={analyticsKeys}
      syncLogSummaries={syncLogSummaries}
      view={view}
      handleChangeWeekView={() => setView("week")}
      handleChangeView={(e) => {
        if (view === "week") {
          setView(e.indexValue);
        } else {
          setView("week");
        }
      }}
    />
  );
};

export default SyncSectionWithState;
